<template>
  <v-row>
    <v-subheader>Minha Conta > Alterar Senha</v-subheader>
    <v-card width="100%">
      <base-header-information
        :title="headerTitle"
        :description="currentHeaderDescription"
      >
      </base-header-information>
      <v-col class="pa-0 ma-0 pb-8">
        <v-card-title class="lighten-2 pb-1 mt-4" >
          <span  style="word-break: break-word">

            Dicas para a criação de uma senha segura:
          </span>
        </v-card-title>

        <v-card-text>
          <p class="mb-2">
            <ul>
              <li>Evite sequências numéricas;</li>
              <li>Deve conter mais de oito caracteres;</li>
              <li>Deve conter números e letras maiúsculas e minúsculas;</li>
              <li>Deve conter pelo menos um caractere especial: !@#$%¨&* </li>
            </ul>
          </p>
          <v-col cols="12" md="8" lg="8" sm="12" class="mt-10">
            <v-form ref="form" v-model="valid" >
              <inputPassword v-model="password" :valuePassword.sync="password" :clearInput="clearPassword"  :password="{ label: 'Digite sua Nova Senha'}" :passwordConfirm="{ label: 'Confirme a Nova Senha'}" ></inputPassword>
              <div class="actions text-center">
                <v-btn
                  color="success"
                  :loading="loading"
                  @click="passwordChange()"
                >
                  Alterar
                </v-btn>
              </div>
            </v-form>
          </v-col>
        </v-card-text>
      </v-col>
    </v-card>
  </v-row>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { authComputed } from '@state/helpers'
import inputPassword from '@src/components/changePassword/inputPassword.vue'
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  components:{
    inputPassword
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      userData: 'changePassword/userData',
    }),
   currentHeaderDescription() {
      return `Altere sua senha seguindo os padrões de segurança`
    },
  },
  data() {
    return {
      clearPassword: false,
      headerTitle: 'Alterar Senha',
      loading: false,
      password: '',
      valid: false,
      e1: true,
      actualPassword: '',
      authError: false,
      rules: [
        (v) => !!v || 'Preencha corretamente',
        (v) => !this.authError || 'Email ou senha invalido',
      ],
    }
  },
  methods: {
    passwordChange() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$store
          .dispatch('changePassword/passwordChange', {
            password:this.password
          }).then(() => {
            this.$store.commit('snackbarFlashMessage/setSuccessEdition')
            this.loading = false;
            this.password = ''
            this.clearPassword = true
          })
        this.clearPassword = false
      }

    },
  },

}
</script>
